import "../modules/accordion.js";
import "../modules/blockquote.js";
import "../modules/dialog.js";
//import "../modules/footer.js";
//import "../modules/header.js";

import "../v3/components/header.js";
import "../v3/components/footer.js";
import "../modules/secondarymenu.js";
import "../modules/subdued.js";
import "../modules/success.js";
import "../modules/youtube-video.js";
import "../modules/banner.js";
import "../cookies.js";
import "../attribution.js";
import "../blanktargets.js";